/* 各种校验 */
const check = {
	is_num:function(num){
		var re=/^[0-9]+.?[0-9]*$/;
		return re.test(num);
	},
	obj_length:function(obj){
		if(!this.is_obj(obj)){
			return 0;
		}
		let len=0;
		for(let i in obj){
			len++;
		}
		return len;
	},
	is_obj:function(obj){
		if(typeof(obj)!='object'){
			return false;
		}else{
			if(Array.isArray(obj)){
				return false;
			}else{
				return true;
			}
		}
	},
	is_arr:function(arr){
		return Array.isArray(arr)
	},
	is_str:function(str){return (typeof(str)=='string')},
	is_fun:function(fun){return (typeof fun === "function")},
	is_bl:function(str){//判断是否是营业执照号码
		var reg=/^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$/;
		return reg.test(str)
	},
	is_bank_card:function(str){//判断是否是银行卡号
		// var reg=/^([1-9]{1})(\d{14}|\d{15}|\d{16}|\d{17}|\d{18}|\d{19}|\d{22})$/;
		// return reg.test(str)

		return this.is_num(str);
	},
	id_card_num:function(str){//判断是否是身份证号码
		var reg=/^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
		return reg.test(str)
	},
	is_post:function(str){//判断是否是邮政编码
		var reg=/^[1-9][0-9]{5}$/;
		return reg.test(str)
	},
	is_tel:function(str){//判断是否是电话号码
		var reg1=/^1[3456789]{1}\d{9}$/;
		var reg2=/^([0-9]{3,4}-)?[0-9]{7,8}$/;
		var reg3=/^\d{3,4}-\d{3,4}-\d{3,4}$/;
		return reg1.test(str)||reg2.test(str)||reg3.test(str)
	},
	is_zone_code:function(str){//区号
		var reg=/^\+?\d{1,3}$/;
		return reg.test(str)
	},
	is_email:function(str){//判断是否是邮箱地址
		var reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/; 
		return reg.test(str); 
	},
	is_url:function(str){//判断是否是邮箱地址
		var reg = /^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-\.,@?^=%&:\/~\+#]*[\w\-\@?^=%&\/~\+#])?$/; 
		return reg.test(str); 
	},
	is_plate_num:function(str){//判断是否是车牌号
		if(!str)return false;
		if(!str.trim())return false;
		return true;
		// var reg = /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/; 
		// return reg.test(str);
	},
	is_vin_code:function(str){//判断是否是车架号
		var reg = /^[A-HJ-NPR-Z\d]{17}$/; 
		return reg.test(str); 
	},
	is_lat:function(str){//判断是否是纬度
		var reg = /^[\-\+]?((0|([1-8]\d?))(\.\d{1,10})?|90(\.0{1,10})?)$/; 
		return reg.test(str); 
	},
	is_lng:function(str){//判断是否是经度
		var reg = /^[\-\+]?(0(\.\d{1,10})?|([1-9](\d)?)(\.\d{1,10})?|1[0-7]\d{1}(\.\d{1,10})?|180\.0{1,10})$/; 
		return reg.test(str); 
	},
	is_login_pwd:function(str){//登录密码格式校验
		let zmReg = /[A-Za-z]/; // 大小写字母
		let numReg = /[0-9]/; // 数字
		let zfReg = /[^A-Za-z0-9\s]/; // 特殊字符不包含空格
		let empty = /\s/g;
		let chinese = /[\u4e00-\u9fa5]/g;
		let complex = 0;
		if (chinese.test(str)) {
			return false;
		}
		if (empty.test(str)) {
			return false;
		}
		if (zmReg.test(str)) {
			++complex;
		}
		if (numReg.test(str)) {
			++complex;
		}
		if (zfReg.test(str)) {
			++complex;
		}
		if (complex < 2 || str.length < 6 || str.length > 16) {
			
			// 密码需包含字母，符号或者数字中至少两项且长度超过6位数，最多不超过16位数
			return false;
		} else {
			return true;
		}
	},
}

export default check