<template>
	<el-button 
		@click="click_in_page" 
		:size="size" 
		:type="type" 
		v-if="is_show" 
	>
		<span ref="__controlled_btn__" >
			<slot></slot>
		</span>
	</el-button>
</template>
<script>
	import {mapState} from 'vuex'
	export default {
		props:{
			size:String,
			type:String,
		},
		data() {
			return {
				is_show:true,
			}
		},
		computed:{
			...mapState(['url_btns'])
		},
		mounted(){
			let page_url=this.$route.path.split('/pages/')[1]
			let this_page_controlled_btns=this.url_btns[page_url]
			let this_btn_name=this.$refs.__controlled_btn__.innerHTML

			if(this_page_controlled_btns.indexOf(this_btn_name)==-1){
				this.is_show=false
			}
		},
		methods: {
			click_in_page(){
				this.$emit('click');
			}
		}
	}
</script>