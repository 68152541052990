/* rsa加密组件 */
import jsencrypt from 'jsencrypt'
const rsa = {
	rsa_pub_key:
	'-----BEGIN PUBLIC KEY-----'+
	'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAiuFFxpayfbYH1NVdetYy'+
	'41UCUfvVIlJivYblAjXZ3joII81j5fRlvrsM+bdch1iHzgnV027unIVNIzrlnTxm'+
	'd5zueH7DlK7QeZMbod/j9Ar6GdJ5CP0b61vft2Pja/TqmiInEI5nO95+7xSHeb7g'+
	'3a48cxSfN2P4Af2WdczA04HHDxGx+wYtM96c0oz7MkwQ9aNPCtdB6jBVdaLTybry'+
	'sPWSdr206pqqlL3UXMmVsMbCNTjrWMCWeuPQt3ySS4BTzFAJOXUo+kwHbD8Nv6SU'+
	'Fvt7e1S1m32IkfBQqd+I4AvrziR+ciN0pdZQ07sfsVghQieZk91UgAXCW7x+xwAt'+
	'jQIDAQAB'+
	'-----END PUBLIC KEY-----',
	encode(data){//加密
		
        //json处理
        if(!this.__parent.check.is_str(data)){
            data=JSON.stringify(data)
        }

        //判空
        if(!data){
            return ''
        }

		//处理数据
		let base64_data=this.__parent.base64.encode(data);
		let strs_data=this.split(base64_data,245);
		
		//开始加密
		let ret="";
		for(var i in strs_data){
			ret+=this.jsencrypt.encrypt(strs_data[i])+";";
		}
		
		return ret
	},
    decode(data){//解密

    },
	split:function(str,len){
		var strArr = [];
		var n = len;
		for (var i = 0, l = str.length; i < l/n; i++) {
			var a = str.slice(n*i, n*(i+1));
			strArr.push(a);
		}
		return strArr;
	},
};

//处理encrapt对象


//
export default {
	install(my){
		this.__parent=my
		rsa.__parent=my
		rsa.jsencrypt=new jsencrypt();
		rsa.jsencrypt.setPublicKey(rsa.rsa_pub_key);
		delete(rsa.rsa_pub_key);
		delete(this.install)
	},
	encode(data){
		return rsa.encode(data);
	},
	decode(data){
		return rsa.decode(data);
	}
}