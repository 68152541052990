export default{
	routes:[{
			path:'*',
			component(){
				return import('@/pages/public/menu.vue')
			}
		},{
			name:'login',
			path:'/login',
			component(){
				return import('@/pages/public/login.vue')
			}
		},{
			name:'index',
			path:'/pages',
			component(){
				return import('@/pages/public/menu.vue')
			},
			children:[

				//app_user部分
				...require('./extend/user_center.js').default,

				//货车部分
				...require('./extend/truck_carrier.js').default,

				//业务部分
				...require('./extend/business.js').default,

				//堆场部分
				...require('./extend/ctyard.js').default,

				//工厂部分
				// ...require('./extend/factory.js').default,

				//港口部分
				// ...require('./extend/port.js').default,

				//车队部分
				// ...require('./extend/truck_task.js').default,
			]
		},
	],
};