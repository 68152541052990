import amap from '@amap/amap-jsapi-loader'
const my={

	//md5(方法)
	md5:require('md5'),

	//base64
	base64:require('./base64.js').default,

	//各种校验
	check:require('./check.js').default,

	//其他工具
	other:require('./other.js').default,

	//快捷缓存(对象)
	cache:require('./cache.js').default,
	
	//rsa(对象)
	rsa:require('./rsa.js').default,

	//网络板块
	net:require('./net.js').default,

	//对象存储
	cos:require('./cos.js').default,
	
	//文件相关操作的封装
	file:require('./file.js').default,

	//高德地图
	amap:amap,

	//页面访问权限检验
	page_auth_check(to,next){

		if(to.name=='login' || to.name=='index' || to.fullPath=='/'){
			next()
			return;
		}
	
		let url_btns=this.$store.state.url_btns
		let page_url=to.path.split('/pages/')[1].split("?")[0]
		if(url_btns[page_url]){

			//缓存url
			this.$store.commit('save_now_url',to.path)

			//前进...
			next()

		}else{
			this.other.msg({
				type:'error',
				str:'无此页面权限'
			});
		}
	},

	//页面内的按钮显示校验
	page_btn_auth(btn_text){
		let page_url=window.location.href.split('/pages/')[1]
		let this_page_controlled_btns=this.$store.state.url_btns[page_url]
		return this_page_controlled_btns.indexOf(btn_text)!=-1
	},
};

//不得而为之 -> 操蛋的js
my.other.install(my);
my.net.install(my)
my.cache.install(my)
my.rsa.install(my)
my.file.install(my)
my.cos.install(my)
my.install=function(app){
	this.__app=app;
	delete(this.install)
}

//路由守卫
my.routerBeforeEach=function(to,from,next){

	let app_my=this;

	//初始化状态(1:未初始化,2:初始化中,3:已初始化成功,4:初始化失败)
	let init_status=app_my.$store.state.init_status

	//未开始初始化
	if(init_status==1){

		//开始初始化
		init({

			//作用域
			that:app_my,

			//初始化完成
			success:(data)=>{

				//已登录
				if(data.is_login==1){

					//页面访问权限校验,如果通过则调用next
					my.page_auth_check(to,next);

				}else if(data.is_login==2){//未登录

					//去哪里?
					if(to.name!='login'){

						//缓存本来要去的页面地址
						app_my.cache.set({
							_before_login_to_page:{
								fullPath:to.fullPath,
							}
						});

						//前往登录页
						next('/login')
						
					}else{

						//走你
						next()
					}
				}
			},
			faild:(res)=>{

				app_my.other.msg({
					type:'error',
					str:'初始化失败'
				});
			}
		})
	}else if(init_status==2){//初始化中
		
		//缓存要去的页面
		if(to.name!='login'){

			app_my.cache.set({
				_before_login_to_page:{
					fullPath:to.fullPath,
				}
			});
		}
	}else if(init_status==3){//已经初始化
		
		//登录状态
		let is_login=app_my.$store.state.is_login

		//已登录
		if(is_login==1){

			//页面访问权限校验,如果通过则调用next
			my.page_auth_check(to,next);

		}else if(is_login==2){//未登录
			
			//去哪里?
			if(to.name!='login'){

				//缓存要去的页面
				app_my.cache.set({
					_before_login_to_page:{
						fullPath:to.fullPath,
					}
				});
				
				//前往登陆页面
				next('/login')

			}else{

				//走你
				next();
			}
		}
	}
}

let init=function(obj){

	let app_my=obj.that

	//调用初始化接口
	app_my.net.req({
		data:{
			mod:'sys',
			ctr:'app_init',
			is_get_cos_bucket:1,
			is_get_map_para:1,
			is_get_language:1,
		},
		take_over_control:2,//强制接管(1:接管content,2:接管整个请求返回,无此参数:不接管任何数据)
		callback:(res)=>{

			/*  初始化失败  */
			//网络传输失败
			if(res.status!=200){

				//保存数据
				app_my.$store.commit('end_of_init',{
					init_status:4,
				})

				//初始化失败处理
				obj.faild();
				return;
			}

			
			//网络传输成功 后端逻辑层返回失败
			if(res.data.code!=0){

				//保存数据
				app_my.$store.commit('end_of_init',{
					init_status:4,
				})

				//初始化失败处理
				obj.faild();
				return;
			}

			/*  初始化成功  */
			//保存初始化状态
			app_my.$store.commit('end_of_init',{
				init_status:3,
				map_para:res.data.msg.map_para,
			})

			//保存登录状态和登录后信息
			app_my.$store.commit('end_of_login',{
				is_login:res.data.msg.is_login,
				app_user_info:res.data.msg.app_user_info,
			})

			//保存session_id
			localStorage.setItem('__apple__mac__windows__set__',res.data.msg.session_id)

			//高德地图初始化
			window._AMapSecurityConfig = {
				securityJsCode:res.data.msg.map_para.amap.web_page.pwd,
			}
			my.amap.load({
			
				// 申请好的Web端开发者Key，首次调用 load 时必填
				key: res.data.msg.map_para.amap.web_page.key,
			
				// 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
				version: "2.0",
			
				// 需要使用的的插件列表，如比例尺'AMap.Scale'等
				plugins: [],
			})

			//读取基础数据
			app_my.net.req({
				data:{
					mod:'basic_data',
					ctr:'all_data',
					is_get_container_type:1,
					is_get_ctyard_fee_type:1,
					is_get_truck_trailer_type:1,
					is_get_truck_van_type:1,
				},
				callback:(basic_data)=>{

					let container_type_obj={};
					let ctyard_fee_type_obj={};
					let truck_trailer_type_obj={};
					let truck_van_type_obj={};
					for(let container_type_item of basic_data.container_type)container_type_obj[container_type_item.id]=container_type_item
					for(let ctyard_fee_type_item of basic_data.ctyard_fee_type)ctyard_fee_type_obj[ctyard_fee_type_item.id]=ctyard_fee_type_item
					for(let truck_trailer_type_item of basic_data.truck_trailer_type)truck_trailer_type_obj[truck_trailer_type_item.id]=truck_trailer_type_item
					for(let truck_van_type_item of basic_data.truck_van_type)truck_van_type_obj[truck_van_type_item.id]=truck_van_type_item
					

					app_my.$store.commit('end_of_init',{
						basic_data:{
							container_type:{arr:basic_data.container_type,obj:container_type_obj},
							ctyard_fee_type:{arr:basic_data.ctyard_fee_type,obj:ctyard_fee_type_obj},
							truck_trailer_type:{arr:basic_data.truck_trailer_type,obj:truck_trailer_type_obj},
							truck_van_type:{arr:basic_data.truck_van_type,obj:truck_van_type_obj},
						},

						//语言相关的参数由于end_of_init的设计缺陷必须放到这里才行
						language_list:res.data.msg.language_list,
						dictionary_list:res.data.msg.dictionary_list,
					})

					//初始化成功处理
					obj.success(res.data.msg);
				}
			});
		}
	});
}

export default my