/* 无法归类的工具 */
import { Loading } from "element-ui";
let other={
	copy(str){//复制 待实现
		
	},
	msg(obj){//消息
		this.__parent.__app.$message({
			type: obj.type,
			message: obj.str,
		})
	},
	loading:{
		start(callback){
			if(this.instance){
				if(other.__parent.check.is_fun(callback)){
					callback({
						code:1,
						error_info:'Loading...',
					});
				}
				return;
			}
			this.instance=Loading.service({fullscreen:true});
			if(other.__parent.check.is_fun(callback)){
				callback({
					code:0,
				});
			}
		},
		close(callback){
			if(!this.instance){
				if(other.__parent.check.is_fun(callback)){
					callback({
						code:0,
					});
				}
				return;
			}
			this.instance.close();
			delete(this.instance);
			if(other.__parent.check.is_fun(callback)){
				callback({
					code:0,
				});
			}
		}
	},
	confirm(obj){//消息

		let title=obj.title?obj.title:'tips';

		let content=obj.content?obj.content:'请确认操作';

		let type=obj.type?obj.type:'warnning';

		this.__parent.__app.$confirm(content,title,{
			confirmButtonText: 'ok',
			cancelButtonText: 'cancel',
			type: type,
			callback: action => {
				if (action === 'confirm') {
					if(this.__parent.check.is_fun(obj.confirm)){
						obj.confirm();
					}
				}else {
					if(this.__parent.check.is_fun(obj.cancel)){
						obj.cancel();
					}
				}
			}
		})
	},
	totime(timestamp,not_need_time){//时间戳转时间字符串
		if(timestamp>99999999999){
			return '无';
		}
		var d = new Date(timestamp * 1000);    //根据时间戳生成的时间对象
		var month=d.getMonth()+1;
		month=month<10?"0"+month:month;
		
		var data=d.getDate();
		data=data<10?"0"+data:data;
		
		if(not_need_time){
			return (d.getFullYear())+"-"+month+"-"+data;
		}
		
		var hour=d.getHours();
		hour=hour<10?"0"+hour:hour;
		
		var minutes=d.getMinutes();
		minutes=minutes<10?"0"+minutes:minutes;
		
		var seconds=d.getSeconds();
		seconds=seconds<10?"0"+seconds:seconds;
		
		return (d.getFullYear())+"-"+month+"-"+data+" "+hour+":"+minutes+":"+seconds;
	},
	todate(timestamp){
		if(timestamp){
			if(timestamp>99999999999){
				return '无';
			}
			var d = new Date(timestamp * 1000);
		}else{
			var d = new Date();
		}
		
		var month=d.getMonth()+1;
		month=month<10?"0"+month:month;
		
		var data=d.getDate();
		data=data<10?"0"+data:data;
		
		return (d.getFullYear())+"-"+month+"-"+data
	},
	url:{
		encode(str){
			return escape(str);
		},
		decode(str){
			return unescape(str);
		},
	},
	obj_length(obj){//对象长度
		if(!this.is_obj(obj)){
			return 0;
		}
		let len=0;
		for(let i in obj){
			len++;
		}
		return len;
	},
}
export default {
	install(my){
		this.__parent=my
		other.__parent=my
		delete(this.install)
	},
	copy(str){
		return other.copy(str)
	},
	msg(obj){
		return other.msg(obj)
	},
	loading:{
		start(callback){
			return other.loading.start(callback)
		},
		close(callback){
			return other.loading.close(callback)
		},
	},
	confirm(obj){
		return other.confirm(obj)
	},
	totime(timestamp,not_need_time){
		return other.totime(timestamp,not_need_time)
	},
	todate(timestamp,not_need_time){
		return other.todate(timestamp,not_need_time)
	},
	url:{
		encode(str){
			return other.url.encode(str)
		},
		decode(str){
			return other.url.decode(str)
		},
	},
	obj_length(obj){
		return other.obj_length(obj)
	},
}