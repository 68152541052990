import qcloud_cos from 'cos-js-sdk-v5'
import { Loading } from "element-ui";
let cos={

	//文件对象缓存
	files_cache:{},

	//上传对象缓存
	obj_cache:{},

	//获取某个key的上传对象
	get_upl_obj(upl_key){

		//如果有直接返回
		if(this.obj_cache[upl_key]){
			return this.obj_cache[upl_key]
		}
		
		//如果不存在则生成上传对象
		this.obj_cache[upl_key]=new qcloud_cos({

			//获取临时密钥
			getAuthorization:(options,callback)=>{

				this.__parent.net.req({
					loading:true,
					data:{
						mod:'sys',
						ctr:'get_cos_auth_info',
						real_bucket:options.Bucket,
						real_region:options.Region,
						real_key:options.Key,
					},
					callback:(res)=>{

						callback({
							TmpSecretId: res.Credentials.TmpSecretId,
							TmpSecretKey: res.Credentials.TmpSecretKey,
							SecurityToken: res.Credentials.Token,
							// 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
							StartTime: res.StartTime, // 时间戳，单位秒，如：1580000000
							ExpiredTime: res.ExpiredTime, // 时间戳，单位秒，如：1580000000
						});
					}
				})
			}
		});

		return this.obj_cache[upl_key]
	},

	//选择文件
	choose(options){
		
		let size=options.size?options.size:'1024000000'//bit
		
		let accept=options.accept?options.accept:'image/*'
		
		//input对象实例
		let input=document.createElement('input')
		input.type='file'
		input.accept=accept
		input.addEventListener('change',()=>{
			if(input.files[0].size>size){
				this.__parent.other.msg({
					type:'warning',
					str:'文件过大'
				});
			}
			
			let res={
				name:input.files[0].name,
				size:input.files[0].size,
				type:input.files[0].type,
				files:input.files
			};
			if(window.createObjectURL!=undefined){ // basic
				res.src = window.createObjectURL(input.files[0]);
			}else if(window.URL!=undefined){ // mozilla(firefox)
				res.src=window.URL.createObjectURL(input.files[0]) ;
			}else if(window.webkitURL!=undefined){ // webkit or chrome
				res.src=window.webkitURL.createObjectURL(input.files[0]) ;
			}
			
			//缓存file对象
			this.files_cache[res.src]=input.files[0]
			
			//回调
			options.success(res)
		})
		input.click()
	},

	//上传
	upl(obj){

		//取出前端bucket定义
		let this_bucket=this.__parent.$store.state.cos_bucket_list[obj.bucket]

		//取出this
		let that=this;

		//上传函数
		let upload_file=function(){

			//loading开启
			let instance=Loading.service({fullscreen:true});

			//上传
			that.get_upl_obj(obj.upl_key).uploadFile({
				Bucket: this_bucket.real_bucket,		//真实存储桶
				Region: this_bucket.region,     		//存储桶所在地域
				Key: obj.upl_key,						//存储在桶里的对象键
				Body: that.files_cache[obj.file_path], 	//上传文件对象
				SliceSize: 1024000000,					//触发分块上传的阈值,现为100MB
			},(err,data)=>{

				//loading开启
				instance.close();

				if(err){
					that.__parent.other.msg({
						type:'warning',
						str:'上传失败'
					});
					return;
				}
				
				//删除files_cache缓存
				delete(that.files_cache[obj.file_path])
				
				//生成携带version的key 并执行回调
				let new_version=obj.version+1;
				obj.callback({
					key:obj.upl_key+"__v__"+new_version,
					upl_key:obj.upl_key,
					varsion:new_version,
				})
			})
		};

		//如果没有传入key, 则去后端获取key
		if(!obj.key){

			//调用接口
			this.__parent.net.req({
				data:{
					mod:'sys',
					ctr:'get_cos_keys',
					bucket:obj.bucket,
					amount:1,
				},
				callback:(res)=>{
					obj.upl_key=res.keys[0];
					obj.version=0;
					upload_file();
				}
			})
		}else{
			let key_version=this.get_key_version(obj.key)
			obj.upl_key=key_version.upl_key
			obj.version=key_version.version
			upload_file();
		}
	},

	//从原始key上解析 upl_key和version
	get_key_version(key){
		let key_arr=key.split('__v__')
		return {
			upl_key:key_arr[0].trim(),
			version:parseInt(key_arr[1]),
		}
	},

	//生成图片地址
	make_src(bucket,key){

		if(!(bucket && key)){
			return;
		}
		
		//取出 buckets
		let buckets=this.__parent.$store.state.cos_bucket_list
		let this_bucket=buckets[bucket]
		
		//分离 upl_key 和 version
		let key_version=this.get_key_version(key)
		if(!key_version.upl_key){
			this.__parent.other.msg({
				type:'warning',
				str:"qiniu插件(qiniu.js):make_src函数(第159行)出错,请前往查看"
			});
			return;
		}
		
		//拼接并返回 
		return this_bucket.bucket_url+key_version.upl_key+"?__version__="+key_version.version
	},
}
export default {
	install(my){
		this.__parent=my
		cos.__parent=my
		delete(this.install)
	},
	choose(options){
		return cos.choose(options);
	},
	upl(obj){
		return cos.upl(obj);
	},
	make_src(bucket,key){
		return cos.make_src(bucket,key);
	},
}