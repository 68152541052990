/* 网络组件 */
import axios from 'axios'
const net = {

	//易网泰国服务器
	// api:'https://api.e-link9.com/',

	//测试服
	api:'https://api.tms.enet.why.ski/',

	download(url){
		
		if(!url){
			this.__parent.other.msg({
				type:'error',
				str:'缺少下载地址'
			});
			return;
		}
		
		//发起下载
		var elemIF = document.createElement("iframe");
		elemIF.src = url;
		elemIF.style.display = "none";
		document.body.appendChild(elemIF);
	},
	req(obj){//暴露方法

		//置入请求体
		if(!obj.data){
			obj.data={};
		}

		//置入session_id
		if(!obj.data.session_id){

			//尝试从硬盘读取 __auth_code__
			obj.data.session_id=localStorage.getItem('__apple__mac__windows__set__');
		}

		//判断是否有权限
		if(obj.data.mod=='sys' && obj.data.ctr=='app_init'){//初始化请求 放行
			this.to_req(obj);
		}else if(this.__parent.$store.state.init_status==3){//已经初始化 放行
			this.to_req(obj);
		}else{//未初始化 拦截并缓存请求
			this._before_init_post_buf.push(obj)
		}
	},
	to_req(obj){//非暴露方法
		let api=obj.api?obj.api:this.api
		let method=obj.method?obj.method:'get'
		let loading=obj.loading?true:false;
		if(loading){
			this.__parent.other.loading.start((rel)=>{
				if(rel.code!=0){
					return this.__parent.other.msg({
						type:'error',
						str:rel.error_info
					});
				}
				if(method=='get'){
					axios.get(api+'?rsa_para='+this.__parent.rsa.encode(obj.data)).then((res)=>{
						this.req_back(obj,res);
					})
				}else if(method=='post'){
					axios.post(api,`rsa_para=${this.__parent.rsa.encode(obj.data)}}`).then((res)=>{
						this.req_back(obj,res);
					})
				}
			})
		}else{
			if(method=='get'){
				axios.get(api+'?rsa_para='+this.__parent.rsa.encode(obj.data)).then((res)=>{
					this.req_back(obj,res);
				})
			}else if(method=='post'){
				axios.post(api,`rsa_para=${this.__parent.rsa.encode(obj.data)}}`).then((res)=>{
					this.req_back(obj,res);
				})
			}
		}
	},
	req_back(obj,res){

		let loading=obj.loading?true:false;
		if(loading){
			this.__parent.other.loading.close()
		}

		//take_over_control:1,//强制接管(1:接管content,2:接管整个请求返回,无此参数:不接管任何数据)

		//接管整个请求返回
		if(obj.take_over_control==2){

			//有回调
			if(this.__parent.check.is_fun(obj.callback)){
				obj.callback(res);
				return;
			}
		}

		//网络请求失败
		if(res.status!=200){
			this.faild(res);
			return;
		}

		//接管content
		if(obj.take_over_control==1){

			//有回调
			if(this.__parent.check.is_fun(obj.callback)){
				obj.callback(res.data);
				return;
			}
		}

		//网络请求成功 但是业务层失败
		if(res.data.code!=0){
			this.faild(res);
			return;
		}

		//仅返回业务层数据
		if(this.__parent.check.is_fun(obj.callback)){
			obj.callback(res.data.msg);
			return;
		}
	},
	faild(res){//请求失败处理方法
		
		//网络请求失败
		if(res.status!=200){
			this.__parent.other.msg({
				type:'error',
				str:'网络请求失败'
			});
			return;
		}

		//网络请求成功 但是业务层失败
		if(res.data.code==2){//未登录

			//前往登录页

			//待实现

		}else if(res.data.code==3){//未实名

			//询问是否前往实名页面
			
			//待实现
			
		}else if(res.data.code==4){//未企业认证
			
			//询问是否前往企业认证页面
			
			//待实现
			
		}else{//普通错误
			this.__parent.other.msg({
				type:'warning',
				str:res.data.error_info
			});
			return;
		}
	},
	_before_init_post_buf:[],
	do_before_init_post_buf(){
		if(this._before_init_post_buf.length>0){
			
			this.to_req({
				data:this._before_init_post_buf[0].data,
				callback:(res)=>{
					
					if(this._before_init_post_buf[0].callback){
						
						this._before_init_post_buf[0].callback(res)
					
					}
					
					this._before_init_post_buf.splice(0,1)
					
					this.do_before_init_post_buf()
				},
			})
		}
	},
}

export default {
	install(my){
		this.__parent=my
		net.__parent=my
		delete(this.install)
	},
	req(obj){
		return net.req(obj)
	},
	download(url){
		return net.download(url)
	}
}