export default [
	{
		path:'truck_carrier/truck_carrier_bind_truck_driver_list',
		component(){
			return import('@/pages/truck_carrier/truck_carrier_bind_truck_driver/list.vue')
		}
	},
	{
		path:'truck_carrier/truck_carrier_bind_truck_van_list',
		component(){
			return import('@/pages/truck_carrier/truck_carrier_bind_truck_van/list.vue')
		}
	},
	{
		path:'truck_carrier/truck_carrier_bind_truck_trailer_list',
		component(){
			return import('@/pages/truck_carrier/truck_carrier_bind_truck_trailer/list.vue')
		}
	},
]