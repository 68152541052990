//临时用,以后放到后端
export default [
	{id:'1',name:'个人中心',fid:'0',weight:'1',controlled_btns:'',url:''},
	{id:'2',name:'用户信息',fid:'1',weight:'2',controlled_btns:'',url:'user_center/user_info'},

	{id:'3',name:'司机车辆',fid:'0',weight:'3',controlled_btns:'',url:''},
	{id:'4',name:'司机列表',fid:'3',weight:'4',controlled_btns:'',url:'truck_carrier/truck_carrier_bind_truck_driver_list'},
	{id:'5',name:'货车列表',fid:'3',weight:'5',controlled_btns:'',url:'truck_carrier/truck_carrier_bind_truck_van_list'},
	{id:'6',name:'挂车列表',fid:'3',weight:'6',controlled_btns:'',url:'truck_carrier/truck_carrier_bind_truck_trailer_list'},

	{id:'7',name:'业务管理',fid:'0',weight:'7',controlled_btns:'',url:''},
	{id:'8',name:'业务列表',fid:'7',weight:'8',controlled_btns:'',url:'business/business_container_list'},
	{id:'9',name:'提箱预约',fid:'7',weight:'9',controlled_btns:'',url:'ctyard/ctyard_reserved_list'},
	{id:'10',name:'装箱预约',fid:'7',weight:'10',controlled_btns:'',url:'factory/factory_reserved_list'},
	{id:'11',name:'进港预约',fid:'7',weight:'11',controlled_btns:'',url:'port/port_reserved_list'},
	{id:'12',name:'运输任务',fid:'7',weight:'12',controlled_btns:'',url:'truck_task/truck_task_list'},
];