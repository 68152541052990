export default {
	
	dict(before_str){
		
		let dictionary_obj
		let language_choosed
		
		if(this.$store){
			dictionary_obj=this.$store.state.dictionary_obj
			language_choosed=this.$store.state.language_choosed
		}else if(this._uid>0){
			dictionary_obj=this.dictionary_obj
			language_choosed=this.language_choosed
		}
		
		let transit_str=''
		if(!language_choosed){
			transit_str=before_str
		}else if(!dictionary_obj[language_choosed]){
			transit_str=before_str
		}else if(!dictionary_obj[language_choosed][before_str]){
			transit_str=before_str
		}else transit_str=dictionary_obj[language_choosed][before_str]
		return transit_str
	},
}