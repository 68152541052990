/* 缓存组件 */

const cache = {
	bucket:{},
	get(para){
		if(this.__parent.check.is_arr(para)){
			
			let out={};
			
			for(var key of para){
				
				if(!this.__parent.check.is_str(key)){
					this.__parent.other.msg("批量读取缓存时传入的每个键名必须是字符串")
					return;
				}
				
				out[key]=this.bucket[key];
				
			}
			
			return {...out};
			
		}else if(this.__parent.check.is_str(para)){
			
			return this.bucket[para];
		}
	},
	set(obj){
		
		if(!this.__parent.check.is_obj(obj)){
			this.__parent.other.msg("写入缓存时必须传入对象")
			return;
		}
		
		for(var key in obj){
			this.bucket[key]=obj[key]
		}
	}
}
//cache.act
export default {
	install(my){
		this.__parent=my
		cache.__parent=my
		delete(this.install)
	},
	get(para){
		return cache.get(para);
	},
	set(obj){
		return cache.set(obj);
	}
}